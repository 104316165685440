import {StateStorage} from "./infrastructure/stateStorage";

class Login {
    private readonly stateStorage : StateStorage;
    private readonly spinnerSelector : string = ".spinner-xs-withtext";

    constructor() {
        this.stateStorage = new StateStorage();
    }

    init() {
        const $this = this;

        $("button[type=submit]").off("click").on("click", (e) => {
            $($this.spinnerSelector).show();
            $this.stateStorage.clearAll();
            $(e.currentTarget).hide();
        });
    }
}

$(() => {
    const login = new Login();
    login.init();
});
